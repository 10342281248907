import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appEinVerify]'
})
export class EinVerifyDirective {

  private readonly maxLength = 9;

  constructor(private el: ElementRef) { }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const allowedKeys = [
      'Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete', 'Enter' // Allow control keys
    ];

    const input = this.el.nativeElement as HTMLInputElement;

    // If input length is already at maxLength, prevent further numeric input
    if (input.value.length >= this.maxLength && !allowedKeys.includes(event.key)) {
      event.preventDefault();
      return;
    }

    // Allow control keys or numeric characters only
    if (
      allowedKeys.includes(event.key) ||
      (event.key >= '0' && event.key <= '9') // Allow digits 0-9
    ) {
      return; // Allow key input
    } else {
      event.preventDefault(); // Block all other keys
    }
  }
}
